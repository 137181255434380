import React, { useState, useEffect } from "react";
import bg from "../../images/bg2.svg";

const DegreeView = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShow(true), 10000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <div
      className="relative block overflow-hidden box-border bg-cover bg-repeat-x show"
      style={{
        backgroundImage: `linear-gradient(to bottom,rgba(146, 146, 146, 0.90),rgba(192, 192, 192, 0.90)),url(${bg})`,
      }}
    >
      <div className="relative flex flex-col flex-wrap justify-center min-h-[110vh]">
        <div className="relative max-w-[1140px] mx-auto">
          {/* Section which will be used other components also */}
          <div className="relative py-5 px-[10px] flex flex-col flex-wrap justify-center box-border">
            {/* Title */}
            <div className="w-full pb-5">
              <h1 className="text-3xl sm:text-[40px] text-white text-center  mb-3 font-bold tracking-[2px]">
                360 DEGREE VIEWS
              </h1>
            </div>
            {/* Subtitle 1 */}
            <div className="w-full pb-5">
              <h2 className="text-2xl sm:text-3xl text-white text-center mb-3 font-bold tracking-[2px]">
                WE CARE ABOUT YOUR WILDLIFE VACATION EXPERIENCE
              </h2>
            </div>
            {show ? (
              <div className="relative overflow-hidden w-full md:w-[800px] lg:w-[1140px]  mx-auto h-[400px] md:h-[650px]">
                <iframe
                  className={
                    "mx-auto mt-8 absolute top-0 left-0 right-0 bottom-0 w-full h-full"
                  }
                  src="https://momento360.com/e/u/003e416c2f6d446c9e1dadb6e751c920?utm_campaign=embed&utm_source=other&heading=0&pitch=0&field-of-view=75&size=medium%3E%3C/iframe%3E%3C/p%3E%3C/div%3E%3C/div%3E%3C/div%3E%3Cdiv%20id=%27stacks_out_857206%27%20class=%27stacks_out%27%3E%3Cdiv%20id=%27stacks_in_857206%27%20class=%27stacks_in%20com_habitualshaker_strippedGridCols_stack%27%3E%3Cdiv%20class="
                  title="momento360"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            ) : (
              <p className="text-white text-center text-xl">
                Please Wait Loading...
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DegreeView;
